<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.link
  text-decoration: none
  color: #555
  &:hover
    div
      background-color: rgba(0,0,0,.05)
</style>
<template lang="pug">
div.container-property
  .container
    .row.justify-content-center.align-items-center.async(style='height: 100vh' :class='{done:done}')
      .col-xl-5.col-lg-6.col-md-7
        h3.title.py-3 워크스페이스 선택
        .border.shadow(style='border-radius: 1.25rem; overflow: hidden')
          router-link.link(v-for='(e, $index) in property' :key='e.id' :to='`/${e.subdomain}`' custom v-slot="{href, navigate}")
            a.link(:href='href' @click='track("클릭_워크스페이스", {e}); navigate($event)')
              div.d-flex.p-4
                strong {{e.name || '(이름없음)'}}
                strong.ml-auto.text-muted.opacity-50 {{e.subdomain}}
              hr.opacity-50.m-0
          //- .d-flex.p-4
          //-   strong 계정 정보
          //-   strong.ml-auto.text-muted.text-right
          //-     span.d-block {{session.name}}
          //-     span.opacity-50 {{session.email}}
          //- hr.opacity-50.m-0
          //- p.mb-0.pt-4.pl-4.opacity-50(style='font-size: 12px; ') 가입을 클릭하시면 해당 워크스페이스에 계정이 추가되고
          //-   br
          //-   | 접속 권한을 받습니다.
          .d-flex.p-4
            div.w-100.mr-2
              router-link(to='/property/new' custom v-slot="{href, navigate}")
                a.btn-block.btn.btn-primary.p-4(:href='href' @click='track("클릭_새로만들기"); navigate($event)' style='border-radius: .9rem') 새로 만들기
            div.w-100
              router-link(to='/logout' custom v-slot="{href, navigate}")
                a.btn.btn-light.text-primary.bg-light-primary.p-4.btn-block(:href='href' @click='track("클릭_로그아웃"); navigate($event)' style='border-radius: .9rem') 로그아웃
        .mb-4

</template>

<script>

export default {
  name: 'index',
  components: {

  },
  computed: {
    // session() {
    //   return this.$store.state.session
    // },
  },
  mounted() {
    this.load()
    this.track('화면_진입')
  },
  data() {
    return {
      done: false,
      property: [],
    }
  },
  methods: {
    track(name, prop) {
      window.amplitude?.getInstance().logEvent(name, Object.assign({view:"로그인", subview:"워크스페이스목록"}, prop))
    },
    async load() {
      try {
        const r = await this.$http.get('/v2/property')
        this.property = r.data.property
        // if (this.property.length == 1) {
        //   this.$router.push({
        //     path: `/property/${this.property[0].id}`,
        //   })
        // }
        this.done = true
      } catch (error) {
        console.log({error})
      }
    },

  },
}
</script>
