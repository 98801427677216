var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-property"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center align-items-center async",class:{done:_vm.done},staticStyle:{"height":"100vh"}},[_c('div',{staticClass:"col-xl-5 col-lg-6 col-md-7"},[_c('h3',{staticClass:"title py-3"},[_vm._v("워크스페이스 선택")]),_c('div',{staticClass:"border shadow",staticStyle:{"border-radius":"1.25rem","overflow":"hidden"}},[_vm._l((_vm.property),function(e,$index){return _c('router-link',{key:e.id,staticClass:"link",attrs:{"to":("/" + (e.subdomain)),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"link",attrs:{"href":href},on:{"click":function($event){_vm.track("클릭_워크스페이스", {e: e}); navigate($event)}}},[_c('div',{staticClass:"d-flex p-4"},[_c('strong',[_vm._v(_vm._s(e.name || '(이름없음)'))]),_c('strong',{staticClass:"ml-auto text-muted opacity-50"},[_vm._v(_vm._s(e.subdomain))])]),_c('hr',{staticClass:"opacity-50 m-0"})])]}}],null,true)})}),_c('div',{staticClass:"d-flex p-4"},[_c('div',{staticClass:"w-100 mr-2"},[_c('router-link',{attrs:{"to":"/property/new","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"btn-block btn btn-primary p-4",staticStyle:{"border-radius":".9rem"},attrs:{"href":href},on:{"click":function($event){_vm.track("클릭_새로만들기"); navigate($event)}}},[_vm._v("새로 만들기")])]}}])})],1),_c('div',{staticClass:"w-100"},[_c('router-link',{attrs:{"to":"/logout","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{staticClass:"btn btn-light text-primary bg-light-primary p-4 btn-block",staticStyle:{"border-radius":".9rem"},attrs:{"href":href},on:{"click":function($event){_vm.track("클릭_로그아웃"); navigate($event)}}},[_vm._v("로그아웃")])]}}])})],1)])],2),_c('div',{staticClass:"mb-4"})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }